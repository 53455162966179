:root {
  --main-dark: #102027;
  --main-light: #ffffff;

  --accent-red: #f44336;
  --accent-white: #fffdec
}

html {
  font-size: 16px
}

body {
  margin: 0;

  background-color: var(--accent-white)
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth
}

header, main, footer {
  padding-right: 1.5rem;
  padding-left: 1.5rem
}

@media screen and (min-width: 1400px) {
  header, main, footer {
    padding-right: 9%;
    padding-left: 9%
  }
}

footer {
  padding-top: 4rem;
  padding-bottom: 4rem
}

h1, h2, cite, input, textarea {
  font-family: Georgia, Times, 'Times New Roman', serif
}

p, a, p span, article span {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif
}

h1, h2, section.quotes p, span, b {
  font-weight: 700
}

h1, h2, section > p, footer > p, section > span {
  display: block;
  max-width: 800px
}

h2 {
  margin-top: 4rem;
  margin-bottom: 1rem;

  font-weight: normal;
  font-size: 2rem;

  line-height: 1
}

section:first-child h2 {
  margin-top: 0
}

h2 + p, p:first-child {
  margin-top: 0
}

p {
  margin-top: 1rem;
  margin-bottom: 0;

  font-weight: normal;
  line-height: 1.5
}

a {
  color: inherit;

  transition: color ease-in 0.1s
}

a:hover, a.hovered {
  color: var(--accent-red)
}

a:focus {
  color: var(--accent-red);

  outline: none
}

@media screen and (min-width: 901px) {
  section.quotes {
    column-count: 3;
    column-gap: 1rem
  }
  p {
    font-size: 1.5rem
  }
}

section.quotes blockquote {
  display: inline-block;
  margin: 0 0 2rem 0
}

section.quotes p, span {
  display: block;

  line-height: 1.25
}

section.quotes cite {
  margin-top: 0.5rem
}

section {
  color: var(--main-dark)
}

img {
  display: block;
  width: 100%;
  margin-top: 1rem;

  border-radius: 0.25rem
}

cite {
  display: block
}

section.items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem 1rem
}

section.items article {
  position: relative
}

section.items article img {
  margin-top: 0
}

section.items article span {
  display: block;
  margin-top: 0.5em;

  color: var(--main-dark);
  font-size: 1.25rem
}

section.season {
  margin: 0 -1rem;
  padding: 1rem 1rem 2rem 1rem;

  background-color: var(--accent-red);
  border-radius: 0.5rem
}