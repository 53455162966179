header {
	padding-top: 2rem;
	padding-bottom: 1rem;

	background-color: var(--accent-white)
}

header h1 {
	margin-top: 0;
	margin-bottom: 0;

	letter-spacing: -0.05rem
}

@media screen and (max-width: 900px) {
	header h1 {
		font-size: 1.5rem
	}
}

@media screen and (min-width: 901px) {
	header h1 {
		font-size: 2rem
	}
}

header h1 {
	color: var(--main-dark);
	line-height: 1
}

header h1 span {
	color: var(--accent-red)
}